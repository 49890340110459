/* You can add global styles to this file, and also import other style files */
/*
* {
  font-size: 62.5% !important;
}

body {
  font-size: 1.6rem !important;
  background-color: red !important;
}
*/
:host {
  --color-thrash-custom:rgba(198, 72, 64, 1) !important;
}

